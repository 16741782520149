import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import GeneralStatic from '@utilities/static/GeneralStatic';

const ErrorMessage = ({ showError, className, error }) => (
  <TransitionGroup component={null}>
    { showError && error && (
      <CSSTransition classNames="item--slide-short" timeout={GeneralStatic.transitionDefault}>
        <div className={`${className} b-around--xs b--red b--rounded-md bg--light-pink layout-row layout-align-center-center p-vertical--xx-small p-horizontal--small ta-center`}>
          <span className="meta strong red">{ error }</span>
        </div>
      </CSSTransition>
    )}
  </TransitionGroup>
);

ErrorMessage.propTypes = {
  // Required
  showError: PropTypes.bool.isRequired,
  // Optional
  className: PropTypes.string,
  error: PropTypes.string,
};

ErrorMessage.defaultProps = {
  className: '',
  error: '',
};

export default ErrorMessage;
