export default {
  direct_booking: {
    monthly: [
      19,
      12,
      10,
      9,
      8,
      7,
      7,
      7,
      7,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      3.9,
    ],
    yearly: [
      14.92,
      9.50,
      7.92,
      7.08,
      6.34,
      5.50,
      4.92,
      4.92,
      4.92,
      4.59,
      4.59,
      4.59,
      4.59,
      4.59,
      4.59,
      4.59,
      4.59,
      4.59,
      4.59,
      4.09,
      4.09,
      4.09,
      4.09,
      4.09,
      4.09,
      4.09,
      4.09,
      4.09,
      4.09,
      3.75,
      3.75,
      3.75,
      3.75,
      3.75,
      3.75,
      3.75,
      3.75,
      3.75,
      3.75,
      3.75,
      3.75,
      3.75,
      3.75,
      3.75,
      3.75,
      3.75,
      3.75,
      3.75,
      3.75,
      3.70,
    ],
  },
  basic: {
    monthly: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
    yearly: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
  },
};
