import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import Image from "next/legacy/image";
import Link from 'next/link';
import InputRange from 'react-input-range';
import { addDays, format } from 'date-fns';
import Breadcrumbs from '@components/Navigation/Breadcrumbs';
import Button from '@components/Core/Button';
import EmptyStateContainer from '@components/Common/EmptyStateContainer';
import Input from '@components/Forms/Input';
import { ArrowForwardIcon, SquiggleArrowLeftIcon, SquiggleArrowRightIcon } from '@components/Icons';
import Checklist from '@components/LandingPages/Checklist';
import EditCreditCard from '@components/Payments/EditCreditCard';
import SelectPlanOption from '@components/Subscriptions/SelectPlanOption';
import Price from '@components/Text/Price';
import Tag from '@components/Text/Tag';
import SubscriptionConstants from '@domain/Subscriptions/SubscriptionConstants';
import SubscriptionPlanConstants from '@domain/Subscriptions/SubscriptionPlanConstants';
import Actions from '@redux/actions';
import AnalyticsService from '@services/AnalyticsService';
import ImageService from '@services/ImageService';
import LocalStorageService from '@services/LocalStorageService';
import SubscriptionPricingStatic from '@utilities/static/SubscriptionPricingStatic';
import StringUtility from '@utilities/StringUtility';

const MIN_NUM_PROPERTIES = 1;
const MAX_NUM_PROPERTIES = 50;
const DEFAULT_NUM_PROPERTIES = 1;
const DEFAULT_DURATION = SubscriptionConstants.MONTHLY;
const DEFAULT_PER_DURATION = SubscriptionConstants.MONTHLY.substring(0, SubscriptionConstants.MONTHLY.length - 2);

const PLAN_MAP = {
  [SubscriptionPlanConstants.DIRECT_BOOKING]: 'Direct Booking Pro',
  [SubscriptionPlanConstants.BASIC]: 'Free',
};

const BASIC_CHECKLIST_ITEMS = [
  <span key="fee">Service fee of <b>3-12%</b></span>,
  'Reservation inbox ',
  'Online payment system',
  'Security deposit returns',
  'iCal sync ',
  'Mobile-friendly website',
];

const DIRECT_BOOKING_PRO_CHECKLIST_ITEMS = [
  <span key="fee">Service fee of <b>0%</b></span>,
  'Reservation inbox ',
  'Online payment system',
  'Security deposit returns',
  'iCal sync ',
  'Mobile-friendly website',
  'Direct booking website',
  'Custom domains',
  'Premium support',
  'Multi-calendar (coming soon)',
];

const STEP1 = {
  label: '1. Choose plan details',
  stepIndex: 1,
  key: 'plan',
};

const STEP2 = {
  label: '2. Add payment method',
  stepIndex: 2,
  key: 'checkout',
};

const STEP3 = {
  hideStep: true,
  stepIndex: 3,
  key: 'success',
};

const SUBSCRIPTION_STEPS = [STEP1, STEP2, STEP3];

const formula = (newNumProperties, duration, showYearlyTotal = false, plan = SubscriptionPlanConstants.DIRECT_BOOKING) => {
  let total = newNumProperties * SubscriptionPricingStatic[plan][duration][newNumProperties - 1];
  if (showYearlyTotal && duration === SubscriptionConstants.YEARLY) {
    total = total * 12;
  }
  return Math.round(total);
};

const SubscriptionCalculator = ({ createSubscriptionRequest, selectPlanCallback, onStepChange, router, tokenRequest, type, user, wrapperClassName }) => {
  const [currentStep, setCurrentStep] = useState(
    router.query.subscriptionStep ? (SUBSCRIPTION_STEPS.find(i => i.key === router.query.subscriptionStep) || SUBSCRIPTION_STEPS[0]) : SUBSCRIPTION_STEPS[0]
  );
  const [basicPrice, setBasicPrice] = useState(formula(DEFAULT_NUM_PROPERTIES, SubscriptionConstants.YEARLY, true, SubscriptionPlanConstants.BASIC));
  const [directBookingProPrice, setDirectBookingProPrice] = useState(formula(DEFAULT_NUM_PROPERTIES, DEFAULT_DURATION));
  const [duration, setDuration] = useState(DEFAULT_DURATION);
  const [farthestCompletedStep, setFarthestCompletedStep] = useState(1);
  const [perDuration, setPerDuration] = useState(DEFAULT_PER_DURATION);
  const [properties, setProperties] = useState(DEFAULT_NUM_PROPERTIES);
  const [selectedPlan, setSelectedPlan] = useState(SubscriptionPlanConstants.DIRECT_BOOKING);
  const [showYearlyTotal, setShowYearlyTotal] = useState(type === currentStep.stepIndex === 2);
  const [typedProperties, setTypedProperties] = useState(DEFAULT_NUM_PROPERTIES);
  const analyticsService = new AnalyticsService();

  useEffect(() => {
    if (router.query.properties) {
      updateProperties(parseInt(router.query.properties));
    }
    if (router.query.duration) {
      setDuration(router.query.duration);
    }
  }, [router.query]);

  useEffect(() => {
    setBasicPrice(formula(properties, SubscriptionConstants.YEARLY, true, SubscriptionPlanConstants.BASIC));
  }, [directBookingProPrice]);

  useEffect(() => {
    setDirectBookingProPrice(formula(properties, duration, showYearlyTotal));
  }, [properties]);

  useEffect(() => {
    if (showYearlyTotal) {
      setPerDuration(duration.substring(0, duration.length - 2));
    }
    setDirectBookingProPrice(formula(properties, duration, showYearlyTotal));
  }, [duration, showYearlyTotal]);

  useEffect(() => {
    setFarthestCompletedStep(Math.max(currentStep.stepIndex, farthestCompletedStep));
    setShowYearlyTotal(currentStep.stepIndex === 2);
    if (onStepChange) {
      onStepChange(duration, properties, currentStep.key);
    }
    if (currentStep.stepIndex === 2) {
      analyticsService.track('Lead Chose Plan');
    }
  }, [currentStep]);

  const handleInputChange = (key, event) => {
    let { value } = event.target;
    // Filter out non-numbers (there is Chrome bug with number inputs: https://github.com/facebook/react/issues/13752)
    value = parseInt(value.replace(/[^0-9]/g, ''));

    updateProperties(value);
  };

  const updateProperties = (value) => {
    setTypedProperties(value);

    if (value > MAX_NUM_PROPERTIES) {
      value = MAX_NUM_PROPERTIES;
    }

    if (value) {
      setProperties(value);
    }
  };

  const payForPlan = async (paymentMethod) => {
    try {
      await createSubscriptionRequest(user.get('id'), paymentMethod.get('id'), selectedPlan, duration, properties);
      await tokenRequest();

      if (selectPlanCallback) {
        selectPlanCallback();
      } else {
        setCurrentStep(SUBSCRIPTION_STEPS[2]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderSimple = () => {
    if (![SubscriptionConstants.SIMPLE, SubscriptionConstants.DEMO].includes(type)) return null;

    const trackSignupPage = () => {
      LocalStorageService.setItem('signupPage', 'direct-booking-pro');
    };

    return (
      <div className="p-around--large">
        { properties < MAX_NUM_PROPERTIES && (
          <>
            <div className="layout-row layout-align-center-center">
              <h2 className="h1"><Price amount={directBookingProPrice} /></h2>
              <span className="meta m-left--small">per { perDuration }</span>
            </div>

            { type === SubscriptionConstants.DEMO && (
              <div className="w--100p m-top--x-large">
                <Link
                  className="button primary"
                  href="/signup?role=landlord"
                  onClick={trackSignupPage}
                >
                  
                  Start free trial
                  
                </Link>
              </div>
            )}
          </>
        )}

        <Checklist itemClassName="flex-gt-xs-50" items={DIRECT_BOOKING_PRO_CHECKLIST_ITEMS} wrapperClassName="m-top--small layout-gt-xs-row layout-wrap" />
      </div>
    );
  };

  const renderOptions = () => {
    if (type !== SubscriptionConstants.OPTIONS) return null;

    return (
      <div className="m-horizontal--auto m-top--medium layout-row layout-align-center-start">
        <div className="hide-xs hide-sm ta-right m-top--x-large m-right--medium">
          <SquiggleArrowRightIcon className="h--80 gray-0" />
          <p className="mw--176 gray-0 m-right--small">Great for sublets and people with less than 3 listings</p>
        </div>
        <div className="mw--660 w--100p layout-gt-xs-row layout-align-start-stretch">
          <div className="flex-gt-xs-50 flex-order-gt-xs-2 p-left--x-small-gt-xs">
            <SelectPlanOption
              buttonClassName="secondary"
              buttonText={`Start ${SubscriptionPlanConstants.TRIAL_DAYS}-day free trial`}
              checklist={DIRECT_BOOKING_PRO_CHECKLIST_ITEMS}
              hidePrice={properties >= MAX_NUM_PROPERTIES}
              isDark
              onClick={() => {
                setSelectedPlan(SubscriptionPlanConstants.DIRECT_BOOKING);
                setCurrentStep(SUBSCRIPTION_STEPS[1]);
              }}
              price={directBookingProPrice}
              title="Direct Booking Pro"
              wrapperClassName="h--100p bg--hue-0 white"
            />
          </div>
          <div className="flex-gt-xs-50 m-top--medium-lt-sm p-right--x-small-gt-xs">
            <SelectPlanOption
              checklist={BASIC_CHECKLIST_ITEMS}
              onClick={selectPlanCallback}
              price={basicPrice}
              title="Free"
              wrapperClassName="h--100p bg--gray-3"
            />
          </div>
        </div>
        <div className="hide-xs hide-sm m-top--x-large m-left--medium">
          <SquiggleArrowLeftIcon className="h--80 gray-0" />
          <p className="mw--176 gray-0 m-left--small">Great for hosts who want an all-in-one direct booking website</p>
        </div>
      </div>
    );
  };

  const renderTitle = () => {
    if (type === SubscriptionConstants.DEMO || currentStep.stepIndex === 3) return null;

    let titleText;
    let subTitleText;
    if (currentStep.stepIndex === 1) {
      titleText = type === SubscriptionConstants.SIMPLE ? 'Choose plan details' : 'Select your plan.';
      subTitleText = type === SubscriptionConstants.SIMPLE ? `Select your number of rental properties and choose your payment plan for your ${PLAN_MAP[selectedPlan]} subscription.` : 'Choose the plan that works best for you and your rental business.';
    } else {
      titleText = 'Add payment method';
      subTitleText = 'Your credit card won’t be charged yet.';
    }

    return (
      <div className="ta-center">
        { type === SubscriptionConstants.SIMPLE && (
          <Breadcrumbs
            canGoBack
            currentStep={currentStep}
            farthestCompletedStep={farthestCompletedStep}
            setCurrentStep={setCurrentStep}
            steps={SUBSCRIPTION_STEPS}
            wrapperClassName="m-bottom--xx-large layout-row layout-align-center-center"
          />
        )}
        <h2>{ titleText }</h2>
        <p className="m-top--small">{ subTitleText }</p>
      </div>
    );
  };

  const renderCalculator = () => {
    if (currentStep.stepIndex > 1) return null;

    return (
      <div className={wrapperClassName}>
        <div className={`mw--660 w--100p m-horizontal--auto b--gray-2 b-bottom--xs ${type === SubscriptionConstants.OPTIONS ? 'b-around--xs b--rounded' : ''}`}>
          <div className="p-around--large">
            <h4 className="ta-center">Number of rental properties</h4>
            <div className="m-top--medium layout-row layout-align-start-center">
              <Input
                ariaLabel="Number of properties"
                className="w--80"
                handleChange={handleInputChange}
                id="properties-input"
                max={1000}
                min={MIN_NUM_PROPERTIES}
                name="properties"
                step="1"
                type="number"
                value={typedProperties}
              />
              <div className="m-left--medium flex">
                <InputRange
                  maxValue={MAX_NUM_PROPERTIES}
                  minValue={MIN_NUM_PROPERTIES}
                  onChange={updateProperties}
                  step={1}
                  value={properties}
                />
              </div>
            </div>
            { properties >= MAX_NUM_PROPERTIES && (
              <EmptyStateContainer wrapperClassName="ta-center m-top--medium">
                <h4 className="inline">Have 50+ listings?</h4>
                <a className="underline m-left--xx-small" href="/contact">Contact us</a>
              </EmptyStateContainer>
            )}
          </div>

          <div className="h--64 layout-row layout-align-center-center b-top--xs b--gray-2">
            <a className={`h--100p black hover-none inline-flex m-right--small ${duration === SubscriptionConstants.MONTHLY ? 'selected-tab' : ''}`} onClick={setDuration.bind(this, SubscriptionConstants.MONTHLY)}>
              <h4>Monthly</h4>
            </a>
            <a className={`h--100p black hover-none inline-flex m-left--small ${duration === SubscriptionConstants.YEARLY ? 'selected-tab' : ''}`} onClick={setDuration.bind(this, SubscriptionConstants.YEARLY)}>
              <h4 className="m-right--x-small">Yearly</h4>
              <Tag state="light-blue" title="Save 20%" />
            </a>
          </div>
        </div>

        { renderSimple() }

        { renderOptions() }
      </div>
    );
  };

  const renderCheckout = () => {
    if (currentStep.stepIndex !== 2) return null;

    const trialEnds = format(addDays(new Date(), SubscriptionPlanConstants.TRIAL_DAYS), 'MMMM d, yyyy');

    return (
      <>
        <div className="mw--660 w--100p b-around--xs b--gray-2 b--rounded m-horizontal--auto m-top--xx-large p-around--large">
          <div className="layout-row layout-align-space-between-center">
            <div className="m-right--small">
              <h4>{ PLAN_MAP[selectedPlan] }</h4>
              <p className="inline m-right--xx-small">{ properties } { StringUtility.pluralize('listing', properties) }, paid { duration }</p>
              <Button className="link pacific strong underline hover-none inline" onClick={setCurrentStep.bind(this, SUBSCRIPTION_STEPS[0])}>
                Change plan
              </Button>
            </div>
            <div className="ta-right flex-no-shrink">
              <Price amount={selectedPlan === SubscriptionPlanConstants.DIRECT_BOOKING ? directBookingProPrice : basicPrice} isHeading />
              <span className="meta">per { perDuration }</span>
            </div>
          </div>
          { duration === SubscriptionConstants.YEARLY && (
            <div className="m-top--large">
              <Tag className="w--100p ta-center" state="light-blue" title="You’re saving 20% by paying yearly instead of monthly." />
            </div>
          )}
        </div>

        { type !== SubscriptionConstants.DEMO && (
          <div className="mw--660 m-horizontal--auto m-top--xx-large">
            <EditCreditCard
              buttonText={`Start ${SubscriptionPlanConstants.TRIAL_DAYS}-day free trial`}
              onSuccess={payForPlan}
              showLabel
            />
            <p className="meta ta-center gray-0 m-top--medium">
              First { SubscriptionPlanConstants.TRIAL_DAYS } days free. Cancel anytime.<br /><br />
              After your free trial ends on { trialEnds }, you’ll be charged ${ directBookingProPrice }/{ perDuration }. Your subscription will auto-renew each { perDuration } unless you cancel before the renewal. To change or cancel your subscription, see Subscription in Account Settings.
            </p>
          </div>
        )}
      </>
    );
  };

  const renderSuccess = () => {
    if (currentStep.stepIndex !== 3) return null;

    return (
      <div className="mw--660 ta-center m-horizontal--auto m-top--xx-large">
        <Image alt="High Five!" height={100} priority src={`${ImageService.highFives}.png`} width={128} />
        <h2 className="m-top--medium">Your account is ready to go!</h2>
        <p className="m-top--small">Create your custom direct booking website by adding details to your profile.</p>
        <div className="w--100p m-top--x-large">
          <Link
            className="primary button hover-none"
            href={`/users/${user.get('id')}/edit`}
          >
            
            Edit my profile
            
          </Link>
        </div>
      </div>
    );
  };

  return (
    <>
      { renderTitle() }

      { renderCalculator() }

      { renderCheckout() }

      { renderSuccess() }

      { type === SubscriptionConstants.SIMPLE && currentStep.stepIndex === 1 && properties < MAX_NUM_PROPERTIES && (
        <>
          <Button
            className="button primary w--100p center-items m-top--x-large"
            onClick={() => {
              setSelectedPlan(SubscriptionPlanConstants.DIRECT_BOOKING);
              setCurrentStep(SUBSCRIPTION_STEPS[1]);
            }}
          >
            Continue
            <ArrowForwardIcon className="icon--16 m-left--x-small" />
          </Button>
          <p className="meta ta-center gray-0 m-top--medium">
            You won’t be charged yet.
          </p>
        </>
      )}
    </>
  );
};

SubscriptionCalculator.propTypes = {
  // Required
  createSubscriptionRequest: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  tokenRequest: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  // Optional
  selectPlanCallback: PropTypes.func,
  onStepChange: PropTypes.func,
  wrapperClassName: PropTypes.string,
};

SubscriptionCalculator.defaultProps = {
  selectPlanCallback: null,
  onStepChange: null,
  wrapperClassName: '',
};

const mapStateToProps = (state) => ({
  user: state.get('User').get('object'),
});

export default withRouter(
  connect(mapStateToProps, {
    createSubscriptionRequest: Actions.createSubscriptionRequest,
    tokenRequest: Actions.tokenRequest,
  })(SubscriptionCalculator)
);
