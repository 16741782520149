import React, { useState } from 'react';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Button from '@components/Core/Button';
import ErrorMessage from '@components/Forms/ErrorMessage';
import Actions from '@redux/actions';

const stripePromise = loadStripe(process.env.STRIPE_API_KEY);

const EditCreditCard = ({ buttonText, createPaymentMethodRequest, onSuccess, showLabel, user, wrapperClassName }) => {
  const [errors, setErrors] = useState(null);
  const [showValidation, setShowValidation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event, elements, stripe) => {
    event.preventDefault();
    setIsLoading(true);
    setShowValidation(false);

    if (!elements || !stripe) {
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    const { error, token } = await stripe.createToken(cardElement);

    if (error) {
      setShowValidation(true);
      setErrors(error.message);
    } else {
      const { paymentMethod } = await createPaymentMethodRequest(user.get('id'), {
        source_id: token.id,
        method: 'stripe',
      });

      await onSuccess(fromJS(paymentMethod));
    }

    setIsLoading(false);
  };

  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ elements, stripe }) => (
          <form className={wrapperClassName} onSubmit={(e) => handleSubmit(e, elements, stripe)}>
            { showLabel && <h4>Payment Information</h4> }
            <ErrorMessage
              className="m-top--medium"
              error={errors}
              showError={showValidation}
            />
            <CardElement
              className="b--gray-2 b-around--xs b--rounded m-top--medium p-around--large"
              options={{
                style: {
                  base: {
                    iconColor: '#212121',
                    color: '#212121',
                    fontWeight: 500,
                    fontFamily: 'Soleil, sans-serif',
                    fontSize: '16px',
                    fontSmoothing: 'antialiased',
                    '::placeholder': { color: '#B3ADA5' },
                  },
                  invalid: {
                    iconColor: '#AA3428',
                    color: '#AA3428',
                  },
                },
              }}
            />
            <div className="mw--660 w--100p m-horizontal--auto m-top--x-large">
              <Button className="primary w--100p" disabled={!stripe} isLoading={isLoading} type="submit">
                { buttonText }
              </Button>
            </div>
          </form>

        )}
      </ElementsConsumer>
    </Elements>
  );
};

EditCreditCard.propTypes = {
  // Required
  createPaymentMethodRequest: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  // Optional
  buttonText: PropTypes.string,
  onSuccess: PropTypes.func,
  showLabel: PropTypes.bool,
  wrapperClassName: PropTypes.string,
};

EditCreditCard.defaultProps = {
  buttonText: 'Pay',
  onSuccess: () => { },
  showLabel: false,
  wrapperClassName: '',
};

const mapStateToProps = (state) => ({
  user: state.get('User').get('object'),
});

export default
connect(mapStateToProps, {
  createPaymentMethodRequest: Actions.createPaymentMethodRequest,
})(EditCreditCard);
