import React from 'react';
import PropTypes from 'prop-types';
import Button from '@components/Core/Button';
import Checklist from '@components/LandingPages/Checklist';
import Price from '@components/Text/Price';

const SelectPlanOption = ({ buttonClassName, buttonText, checklist, hideButton, hidePrice, isDark, onClick, perDuration, price, title, wrapperClassName }) => {
  return (
    <div className={`layout-column b--rounded ${wrapperClassName}`}>
      <div className="p-around--large layout-row layout-align-space-between-start">
        <h4>{ title }</h4>
        <div className={`ta-right ${hidePrice ? 'hidden' : ''}`}>
          <Price amount={price} isHeading />
          <span className={`meta ${isDark ? '' : 'gray-0'}`}>per { perDuration }</span>
        </div>
      </div>

      <div className={`layout-column layout-align-space-between-stretch flex p-horizontal--large p-top--small p-bottom--large b-top--xs ${isDark ? 'b--black' : 'b--gray-2'}`}>
        <Checklist items={checklist} />
        { hideButton ? (
          <p className="meta gray-0 m-top--large">This plan is only available for Yearly.</p>
        ) : (
          <Button className={`${buttonClassName} w--100p m-top--large`} onClick={onClick}>
            { buttonText }
          </Button>
        )}
      </div>
    </div>
  );
};

SelectPlanOption.propTypes = {
  // Required
  checklist: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  // Optional
  buttonClassName: PropTypes.string,
  buttonText: PropTypes.string,
  hideButton: PropTypes.bool,
  hidePrice: PropTypes.bool,
  isDark: PropTypes.bool,
  perDuration: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

SelectPlanOption.defaultProps = {
  buttonClassName: 'primary',
  buttonText: 'Get started',
  hideButton: false,
  hidePrice: false,
  isDark: false,
  perDuration: 'month',
  wrapperClassName: '',
};

export default SelectPlanOption;
