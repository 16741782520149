import React from 'react';
import PropTypes from 'prop-types';
import { ArrowRightIcon } from '@components/Icons';

const Breadcrumbs = ({ canGoBack, currentStep, farthestCompletedStep, steps, setCurrentStep, wrapperClassName }) => {
  steps = steps.filter(s => !s.hideStep);

  if (currentStep.stepIndex > steps.length) return null;

  const isDisabled = (step) => {
    return (currentStep.stepIndex < step.stepIndex && farthestCompletedStep < step.stepIndex) || (currentStep.stepIndex > step.stepIndex && !canGoBack);
  };

  const getStepClassName = (step) => {
    let className = '';

    if (isDisabled(step)) {
      className += ' gray-1';
    } else if (currentStep.stepIndex === step.stepIndex) {
      className += ' strong';
    }

    return className;
  };

  const isStepDisabled = (step) => {
    return ((currentStep.stepIndex < step.stepIndex && farthestCompletedStep < step.stepIndex) || (currentStep.stepIndex > step.stepIndex && !canGoBack));
  };

  return (
    <div className={wrapperClassName}>
      { steps.map((step, index) => (
        <div className="layout-row layout-align-start-center" key={`step-${index}`}>
          <a className={`roman meta black ${getStepClassName(step)}`} disabled={isStepDisabled(step)} onClick={setCurrentStep.bind(this, step)}>
            { step.label }
          </a>
          { index < steps.length - 1 && (
            <ArrowRightIcon className={`inline-block icon--10 icon-stroke--xs m-horizontal--x-small ${isDisabled(steps[index + 1]) ? 'icon--gray-1' : ''}`} />
          )}
        </div>
      ))}
    </div>
  );
};

Breadcrumbs.propTypes = {
  // Required
  currentStep: PropTypes.object.isRequired,
  farthestCompletedStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
  // Optional
  canGoBack: PropTypes.bool,
  wrapperClassName: PropTypes.string,
};

Breadcrumbs.defaultProps = {
  canGoBack: false,
  wrapperClassName: 'mw--md layout-row layout-align-start-center',
};

export default Breadcrumbs;
