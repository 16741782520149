import React from 'react';
import PropTypes from 'prop-types';

const EmptyStateContainer = ({ children, wrapperClassName }) => {
  return (
    <div className={`${wrapperClassName} bg--gray-3 b--gray-2 b-around--xs b--rounded m-top--large p-around--large`}>
      { children }
    </div>
  );
};

EmptyStateContainer.propTypes = {
  // Required
  children: PropTypes.node.isRequired,
  // Optional
  wrapperClassName: PropTypes.string,
};

EmptyStateContainer.defaultProps = {
  wrapperClassName: '',
};

export default EmptyStateContainer;
